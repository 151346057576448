<script>

import Icon from "../../Icon";
import DefaultCard_1MetricsButtons from "../default/DefaultCard_1MetricsButtons";
import GerkonCard_1LatestValue from "./GerkonCard_1LatestValue";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_1 from "../default/DefaultCard_1";
import GerkonCard_1Table from "./GerkonCard_1Table";

export default {
    name: "GerkonCard_1",
    extends: DefaultCard_1,
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: DefaultCard_1MetricsButtons,
        LatestValueComponent: GerkonCard_1LatestValue,
        ChartComponent: GerkonCard_1Table,
        Icon
    }
}
</script>
