<script>
import GerkonCard_1 from "./GerkonCard_1";
import GerkonCard_2 from "./GerkonCard_2";
import GerkonCard_3 from "./GerkonCard_3";
import AbstractDefaultCard from "../default/AbstractDefaultCard";

export default {
    name: "AbstractGerkonCard",
    extends: AbstractDefaultCard,
    components: {
        GerkonCard_1,
        GerkonCard_2,
        GerkonCard_3,
    },
    computed: {
        currentCardComponent() {
            switch (this.currentCardDesign) {
                case 3:
                    return GerkonCard_3;
                case 2:
                    return GerkonCard_2;
                default:
                    return GerkonCard_1
            }
        }
    },
}
</script>
